<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Cuentas Corrientes</h4>
            <div class="small text-muted">Vista de cuentas corrientes de proveedores.</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="12">
            <b-row>
              <b-col sm="3">
                <FindObject render="search"
                        type="suppliers" 
                        @select-object="loadSuppliers($event)" 
                        :valueID="filters.suppliers_id"/>
              </b-col> 
              <b-col sm="2">
                <FindObject render="search"
                        type="erpPointsSales" 
                        @select-object="loadPointSales($event)" 
                        :valueID="filters.points_sales_id"/>
              </b-col>                              
              <b-col md="2">
                <b-form-group label="Desde">    
                  <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Hasta">    
                  <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                </b-form-group>
              </b-col>                                 
              <b-col sm="3" class="mt-4">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="Por Factura" @click="setTabActive('invoice')">               
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"                    
                        :fields="tableInvoice.fields"                      
                        :items="formartedItemsInvoice"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"
                        :busy="tableInvoice.isBusy"       
                        v-if="tableInvoice.items.length || tableInvoice.isBusy">

                  <template v-slot:row-details="row">
                    <b-card no-body>
                      <b-row class="p-3">
                        <b-col md="6">
                          <b-table class="mb-0 table-full-detail-purchase"
                                  responsive
                                  head-variant="dark"
                                  :hover="true"
                                  :small="true"
                                  :fixed="true"
                                  :items="tableDetailsPurchase.imputation.items"
                                  :fields="tableDetailsPurchase.imputation.fields"
                                  v-if="tableDetailsPurchase.imputation.items.length">
                              
                            <template v-slot:table-colgroup="scope">
                              <col
                                v-for="field in scope.fields"                    
                                :key="field.key"
                                :style="{ width: field.width }"
                              >
                            </template>           

                            <template v-slot:cell(date)="data"> 
                              {{moment(data.item.date).format('DD/MM/YYYY')}}
                            </template>              

                            <template v-slot:cell(reference)="data"> 
                              {{data.item.reference}}                          
                            </template>

                            <template v-slot:cell(amount_total)="data"> 
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: itemSelectedPurchase.currency.code}).format(data.item.amount_total)}}           
                            </template>         

                          </b-table>
                          <b-alert v-else variant="info" show>No se encontraron comprobantes imputados</b-alert>              
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(date)="data">
                    <div v-if="data.item.date">
                      {{ moment(data.item.date).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(date_expiration)="data">
                    <div v-if="data.item.date_expiration">
                      {{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(description)="data">
                    {{data.item.description}}
                  </template>

                  <template v-slot:cell(debit)="data">
                      <div v-if="data.item.debit" style="color:green;">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.debit)}}
                      </div>                  
                  </template>

                  <template v-slot:cell(credit)="data">
                      <div v-if="data.item.credit" style="color:red;">
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.credit)}})
                      </div>
                  </template>

                  <template v-slot:cell(balance)="data">
                    <div v-if="data.item.debit" style="color:green;">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.balance)}}
                    </div>
                    <div v-if="data.item.credit" style="color:red;">
                      ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.balance)}})
                    </div>
                  </template>

                  <template v-slot:cell(accumulated)="data">
                    <b v-if="data.item.accumulated">                      
                      <b v-if="data.index==0">
                        <span :class="{'current-accounts-accumulate-positive-total':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative-total':parseFloat(data.item.accumulated)<0}">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                        </span>                      
                      </b>
                      <b v-else>
                        <span :class="{'current-accounts-accumulate-positive':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative':parseFloat(data.item.accumulated)<0}">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                        </span>
                      </b>                      
                    </b>     
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="sendPrinter(data.item)" v-if="data.item.typeOperation!='purchase'">
                        <b-icon icon="printer"></b-icon> Imprimir
                      </b-dropdown-item>
                      <b-dropdown-item @click="openDetail(data.item)">
                        <b-icon icon="card-text"></b-icon> Ver Detalle
                      </b-dropdown-item>                      
                    </b-dropdown>
                  </template>                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>              
              </b-tab>                  
              <b-tab title="Pendiente" @click="setTabActive('pending')">            
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"                    
                        :fields="tablePending.fields"
                        :items="formartedItemsPending"
                        selectable
                        select-mode="single"
                        :busy="tablePending.isBusy"       
                        v-if="tablePending.items.length || tablePending.isBusy">

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(date)="data">
                      <div v-if="data.item.date">
                        {{ moment(data.item.date).format('DD MMMM YYYY') }}
                      </div>
                    </template>

                    <template v-slot:cell(date_expiration)="data">
                      <div v-if="data.item.date_expiration">
                        {{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}
                      </div>
                    </template>

                    <template v-slot:cell(description)="data">
                      {{data.item.description}}
                    </template>

                    <template v-slot:cell(debit)="data">
                        <div v-if="data.item.debit" style="color:green;">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.debit)}}
                        </div>                  
                    </template>

                    <template v-slot:cell(credit)="data">
                        <div v-if="data.item.credit" style="color:red;">
                          ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.credit)}})
                        </div>
                    </template>

                    <template v-slot:cell(accumulated)="data">
                      <b v-if="data.item.accumulated">                      
                        <b v-if="data.index==0">
                          <span :class="{'current-accounts-accumulate-positive-total':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative-total':parseFloat(data.item.accumulated)<0}">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                          </span>                      
                        </b>
                        <b v-else>
                          <span :class="{'current-accounts-accumulate-positive':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative':parseFloat(data.item.accumulated)<0}">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                          </span>
                        </b>                      
                      </b>                          
                    </template>  

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                        <b-dropdown-header>Acciones</b-dropdown-header>
                        <b-dropdown-item @click="sendPrinter(data.item)" v-if="data.item.typeOperation!='purchase'">
                          <b-icon icon="printer"></b-icon> Imprimir
                        </b-dropdown-item>
                        <b-dropdown-item @click="openDetail(data.item)">
                          <b-icon icon="card-text"></b-icon> Ver Detalle
                        </b-dropdown-item>                      
                      </b-dropdown>
                    </template>                                     
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
              <b-tab title="Histórica" @click="setTabActive('history')">            
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"                    
                        :fields="tableHistory.fields"
                        :items="formartedItemsHistory"
                        selectable
                        select-mode="single"
                        :busy="tableHistory.isBusy"       
                        v-if="tableHistory.items.length || tableHistory.isBusy">

                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Cargando...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(date)="data">
                    <div v-if="data.item.date">
                      {{ moment(data.item.date).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(date_expiration)="data">
                    <div v-if="data.item.date_expiration">
                      {{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}
                    </div>
                  </template>

                  <template v-slot:cell(description)="data">
                    {{data.item.description}}
                  </template>

                  <template v-slot:cell(debit)="data">
                      <div v-if="data.item.debit" style="color:green;">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.debit)}}
                      </div>                  
                  </template>

                  <template v-slot:cell(credit)="data">
                      <div v-if="data.item.credit" style="color:red;">
                        ({{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.credit)}})
                      </div>
                  </template>

                  <template v-slot:cell(accumulated)="data">
                      <b v-if="data.item.accumulated">                      
                        <b v-if="data.index==0">
                          <span :class="{'current-accounts-accumulate-positive-total':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative-total':parseFloat(data.item.accumulated)<0}">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                          </span>                      
                        </b>
                        <b v-else>
                          <span :class="{'current-accounts-accumulate-positive':parseFloat(data.item.accumulated)>=0, 'current-accounts-accumulate-negative':parseFloat(data.item.accumulated)<0}">
                            {{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.currency}).format(data.item.accumulated)}}
                          </span>
                        </b>                      
                      </b>    
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="sendPrinter(data.item)" v-if="data.item.typeOperation!='purchase'">
                        <b-icon icon="printer"></b-icon> Imprimir
                      </b-dropdown-item>
                      <b-dropdown-item @click="openDetail(data.item)">
                        <b-icon icon="card-text"></b-icon> Ver Detalle
                      </b-dropdown-item>                          
                    </b-dropdown>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>        
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD DETAILS PURCHASE -->
      <!-- podemos hacer un componente que muestre un modal de todos los datos de este comprobantes
           se repite en la vista purchase 
      -->
      <b-modal v-model="modal.detailPurchase.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detailPurchase.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelectedPurchase">
                  <tbody>
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">ID</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        <b>#{{this.itemSelectedPurchase.id}}</b>
                      </td>
                    </tr>       
                    <tr v-if="itemSelectedPurchase.points_sales">
                      <td class="crud-purchase-title-table-custom align-middle">Punto de Venta Imputación</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{this.itemSelectedPurchase.points_sales.point_sale}} - {{this.itemSelectedPurchase.points_sales.name}}                          
                      </td>
                    </tr>                                      
                    <tr v-if="itemSelectedPurchase.type_voucher && itemSelectedPurchase.point_sale">
                      <td class="crud-purchase-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-purchase-value-table-custom align-middle">
                        <b>{{this.itemSelectedPurchase.type_voucher.name + ' '  + this.itemSelectedPurchase.point_sale.toString().padStart(4,'0') + '-' + this.itemSelectedPurchase.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelectedPurchase.staff">
                      <td class="crud-purchase-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{itemSelectedPurchase.staff.name}}  
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">Fecha</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{moment(this.itemSelectedPurchase.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">Fecha Vencimiento</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{moment(this.itemSelectedPurchase.date_expiration).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                      
                    <tr>
                      <td class="crud-purchase-title-table-custom align-middle">Fecha Imputación</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{moment(this.itemSelectedPurchase.date_imputation).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                                                           
                    <tr v-if="itemSelectedPurchase.supplier">
                      <td class="crud-purchase-title-table-custom align-middle">Proveedor</td>
                      <td class="crud-purchase-value-table-custom align-middle">
                        {{this.itemSelectedPurchase.supplier.name}}                        
                      </td>
                    </tr>                                                  
                    <tr v-if="itemSelectedPurchase.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Neto Gravado</td>
                      <td class="crud-purchase-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelectedPurchase.currency.code}).format(this.itemSelectedPurchase.amount_net)}}                        
                      </td>
                    </tr>  
                    <tr v-if="itemSelectedPurchase.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Neto No Gravado</td>
                      <td class="crud-purchase-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelectedPurchase.currency.code}).format(this.itemSelectedPurchase.amount_not_taxed)}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelectedPurchase.currency">
                      <td class="crud-purchase-title-table-custom align-middle">IVA</td>
                      <td class="crud-purchase-value-table-custom align-middle">                                    
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelectedPurchase.currency.code}).format(this.itemSelectedPurchase.amount_iva)}}                        
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelectedPurchase.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Percepciones</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelectedPurchase.currency.code}).format(this.itemSelectedPurchase.amount_perceptions)}}
                      </td>
                    </tr>                    
                    <tr v-if="itemSelectedPurchase.currency">
                      <td class="crud-purchase-title-table-custom align-middle">Total</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelectedPurchase.currency.code}).format(this.itemSelectedPurchase.amount_total)}}</b>                        
                      </td>
                    </tr>       
                    <tr v-if="itemSelectedPurchase.amount_impute!=itemSelectedPurchase.amount_total">
                      <td class="crud-purchase-title-table-custom align-middle">Pendiente de Imputación</td>
                      <td class="crud-purchase-value-table-custom align-middle table-warning">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.itemSelectedPurchase.currency.code}).format(getPendingVoucherPurchase(this.itemSelectedPurchase))}}</b>                        
                      </td>
                    </tr>                                                        
                    <tr v-if="itemSelectedPurchase.observations">
                      <td class="crud-purchase-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        {{this.itemSelectedPurchase.observations}}
                      </td>
                    </tr>                              
                    <tr v-if="itemSelectedPurchase.file">
                      <td class="crud-purchase-title-table-custom align-middle">Documento</td>
                      <td class="crud-purchase-value-table-custom align-middle">                        
                        <b-link :href="itemSelectedPurchase.file" target="blank">
                          <b>Ver Archivo</b>
                        </b-link>
                      </td>
                    </tr>                                                  
                  </tbody>
                </table>
              </b-tab>    

              <b-tab title="Impuestos">                
                <b-row>
                  <b-col>
                    <b-table class="mb-0 table-full-detail-purchase"
                            responsive
                            head-variant="dark"
                            :hover="true"
                            :small="true"
                            :fixed="true"
                            :items="tableDetailsPurchase.iva.items"
                            :fields="tableDetailsPurchase.iva.fields"
                            v-if="tableDetailsPurchase.iva.items.length">                       
                        
                      <template v-slot:table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"                    
                          :key="field.key"
                          :style="{ width: field.width }"
                        >
                      </template>           

                      <template v-slot:cell(iva_condition)="data">
                        {{data.item.iva_condition.name}}
                      </template>

                      <template v-slot:cell(amount_net)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelectedPurchase.currency.code}).format(data.item.amount_net)}}
                      </template>

                      <template v-slot:cell(amount_iva)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelectedPurchase.currency.code}).format(data.item.amount_iva)}}
                      </template>

                      <template v-slot:cell(amount_total)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelectedPurchase.currency.code}).format(data.item.amount_total)}}
                      </template>                      

                    </b-table>                      
                    <b-alert v-else variant="warning" show>Sin discriminación de IVA</b-alert>
                  </b-col>
                  <b-col>
                    <b-table class="mb-0 table-full-detail-purchase"
                            responsive
                            head-variant="dark"
                            :hover="true"
                            :small="true"
                            :fixed="true"
                            :items="tableDetailsPurchase.percepcion.items"
                            :fields="tableDetailsPurchase.percepcion.fields"
                            v-if="tableDetailsPurchase.percepcion.items.length">                       
                        
                      <template v-slot:table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"                    
                          :key="field.key"
                          :style="{ width: field.width }"
                        >
                      </template>           


                      <template v-slot:cell(percepcion)="data">
                        {{data.item.perception.name}}
                      </template>

                      <template v-slot:cell(amount_total)="data">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency:itemSelectedPurchase.currency.code}).format(data.item.amount_total)}}
                      </template>                      

                    </b-table>                      
                    <b-alert v-else variant="warning" show>Sin discriminación de Percepciones</b-alert>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Imputaciones" v-if="tableDetailsPurchase.imputation.items.length">                
                <b-table class="mb-0 table-full-detail-purchase"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailsPurchase.imputation.items"
                        :fields="tableDetailsPurchase.imputation.fields">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(date)="data"> 
                    {{moment(data.item.date).format('DD/MM/YYYY')}}
                  </template>              

                  <template v-slot:cell(reference)="data"> 
                    {{data.item.reference}}                          
                  </template>

                  <template v-slot:cell(amount_total)="data"> 
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.amount_total)}}           
                  </template>         

                </b-table>
              </b-tab>

              <b-tab title="Asiento" v-if="showContabilidad">
                <b-table class="mb-0 table-full-detail-purchase"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailsPurchase.accounting.items"
                        :fields="tableDetailsPurchase.accounting.fields"
                        v-if="tableDetailsPurchase.accounting.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>                                                                                     

                  <template v-slot:cell(code)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_code}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_code}}</span>
                    </div>
                  </template>  

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_name}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_name}}</span>
                    </div>                                                          
                  </template>

                  <template v-slot:cell(debit)="data">
                    <b v-if="data.item.type == 'Debe'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </b>
                  </template>

                  <template v-slot:cell(credit)="data">
                    <b v-if="data.item.type == 'Haber'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </b>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>

          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detailPurchase.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

      <!-- CRUD DETAILS PAYMENTS -->
      <!-- podemos hacer un componente que muestre un modal de todos los datos de este comprobantes
           se repite en la vista payments 
      -->      
      <b-modal v-model="modal.detailPayments.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detailPayments.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelectedPayments">
                  <tbody>
                    <tr>
                      <td class="crud-payments-title-table-custom align-middle">ID</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        <b>#{{this.itemSelectedPayments.id}}</b>
                      </td>
                    </tr>                                       
                    <tr v-if="itemSelectedPayments.type_voucher && itemSelectedPayments.point_sale">
                      <td class="crud-payments-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-payments-value-table-custom align-middle">
                        <b>{{this.itemSelectedPayments.type_voucher.name + ' '  + this.itemSelectedPayments.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelectedPayments.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelectedPayments.staff">
                      <td class="crud-payments-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-payments-value-table-custom align-middle">
                        <div v-if="itemSelectedPayments.staff">
                          {{itemSelectedPayments.staff.name}}
                        </div> 
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-payments-title-table-custom align-middle">Fecha</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{moment(this.itemSelectedPayments.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelectedPayments.point_sale">
                      <td class="crud-payments-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{this.itemSelectedPayments.point_sale.point_sale}} - {{this.itemSelectedPayments.point_sale.name}}                          
                      </td>
                    </tr>                                           
                    <tr v-if="itemSelectedPayments.supplier">
                      <td class="crud-payments-title-table-custom align-middle">Proveedor</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{this.itemSelectedPayments.supplier.name}}                        
                      </td>
                    </tr>                        
                    <tr v-if="itemSelectedPayments.amount_total">
                      <td class="crud-payments-title-table-custom align-middle">Total</td>
                      <td class="crud-payments-value-table-custom align-middle">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.getCurrency}).format(this.itemSelectedPayments.amount_total)}}</b>                        
                      </td>
                    </tr>                         
                    <tr v-if="itemSelectedPayments.observations">
                      <td class="crud-payments-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-payments-value-table-custom align-middle">                        
                        {{this.itemSelectedPayments.observations}}
                      </td>
                    </tr>                         
                    <tr v-if="itemSelectedPayments.amount_pending">
                      <td class="crud-payments-title-table-custom align-middle">Pendiente de Imputación</td>
                      <td class="crud-payments-value-table-custom align-middle table-warning">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.getCurrency}).format(this.itemSelectedPayments.amount_pending)}}</b>                        
                      </td>
                    </tr>                                                                      
                  </tbody>
                </table>
              </b-tab>              
              <b-tab title="Detalle de Pago">                
                <b-table class="mb-0 table-full-detail-payments"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailsPayments.payment.items"
                        :fields="tableDetailsPayments.payment.fields"
                        v-if="tableDetailsPayments.payment.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(medio)="row"> 
                    <div v-if="row.item.type=='CASH'">
                      EFECTIVO            
                    </div>
                    <div v-if="row.item.type=='CHEQUE'">
                      CHEQUE
                    </div>
                  </template>

                  <template v-slot:cell(description)="row"> 
                    <div v-if="row.item.type=='CASH'">
                      <b>CAJA:</b> {{row.item.relation.cash.name}} |
                      <b>MONEDA:</b> {{row.item.relation.currency.name}}
                    </div>
                    <div v-if="row.item.type=='CHEQUE'">
                      <b>NUMERO:</b> {{row.item.relation.number}} |
                      <b>BANCO:</b> {{row.item.relation.bank}} |
                      <b>EMISION:</b> {{moment(row.item.relation.date).format('DD/MM/YYYY')}} |
                      <b>VENCIMIENTO:</b> {{moment(row.item.relation.date_expiration).format('DD/MM/YYYY')}} |
                      <b-link :id="'more-data-cheque-' + row.index">Más Datos ...</b-link>

                      <b-popover :target="'more-data-cheque-' + row.index" triggers="hover" placement="auto">
                        <template #title>Datos Cheque</template>
                        <b>NUMERO:</b> {{row.item.relation.number}} <br>
                        <b>BANCO:</b> {{row.item.relation.bank}} <br>
                        <b>EMISION:</b> {{moment(row.item.relation.date).format('DD/MM/YYYY')}} <br>
                        <b>VENCIMIENTO:</b> {{moment(row.item.relation.date_expiration).format('DD/MM/YYYY')}} <br>
                        <b>EMISOR:</b> {{row.item.relation.transmitter_name}} <br>
                        <b>CUIT:</b> {{row.item.relation.transmitter_documents_number}} <br>
                        <b>OBSERVACIONES:</b> {{row.item.relation.observations}}
                      </b-popover>                      
                    </div>
                  </template>                  

                  <template v-slot:cell(amount)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(row.item.amount_total)}}
                  </template>                  
                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
              <b-tab title="Comprobantes Imputados">                
                <b-table class="mb-0 table-full-detail-payments"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailsPayments.imputation.items"
                        :fields="tableDetailsPayments.imputation.fields"
                        v-if="tableDetailsPayments.imputation.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(date)="row"> 
                    {{moment(row.item.purchase.date).format('DD/MM/YYYY')}}
                  </template>

                  <template v-slot:cell(voucher)="row"> 
                    {{row.item.purchase.type_voucher.name + ' ' + row.item.purchase.point_sale.toString().padStart(4,'0') + '-' + row.item.purchase.number.toString().padStart(8,'0')}}
                  </template>

                  <template v-slot:cell(amount_total)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(row.item.purchase.amount_total)}}
                  </template>               

                  <template v-slot:cell(amount_pending)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(calcPendingVoucherPayments(row.item))}}
                  </template>                                 
                  
                  <template v-slot:cell(amount_impute)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(row.item.amount_total)}}
                  </template>                                 
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>     
              <b-tab title="Asiento" v-if="showContabilidad">
                <b-table class="mb-0 table-full-detail-purchase"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailsPayments.accounting.items"
                        :fields="tableDetailsPayments.accounting.fields"
                        v-if="tableDetailsPayments.accounting.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>                                                                                     

                  <template v-slot:cell(code)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_code}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_code}}</span>
                    </div>
                  </template>  

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_name}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_name}}</span>
                    </div>                                                          
                  </template>

                  <template v-slot:cell(debit)="data">
                    <span v-if="data.item.type == 'Debe'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </span>
                  </template>

                  <template v-slot:cell(credit)="data">
                    <span v-if="data.item.type == 'Haber'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                    </span>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>                                                
            </b-tabs>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detailPayments.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Vue from 'vue'
  import FindObject from '@/components/inc/find/findObject'   
  import Printer from '@/components/inc/printer/printer'

  export default {
    components: {
      FindObject,    
      Printer,    
    }, 
    data: () => {
      return {      
        access: {
          module_id: Modules.COMPRAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'currentsAccounts',
          elements: {}
        }, 
        tableHistory : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'description', label: 'Comprobante', class: "align-middle"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle"},            
            {key: 'debit', label: 'Debe', class: "align-middle text-right"},
            {key: 'credit', label: 'Haber', class: "align-middle text-right"},         
            {key: 'accumulated', label: 'Acumulado', class: "align-middle text-right"},
            {key: 'f_action', label:'', class: "align-middle"},            
          ],                    
          isBusy: false,
        },
        tablePending : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'description', label: 'Comprobante', class: "align-middle"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle"},            
            {key: 'debit', label: 'Debe', class: "align-middle text-right"},
            {key: 'credit', label: 'Haber', class: "align-middle text-right"},         
            {key: 'accumulated', label: 'Acumulado', class: "align-middle text-right"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],                    
          isBusy: false,
        },  
        tableInvoice : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'description', label: 'Comprobante', class: "align-middle"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle"},            
            {key: 'debit', label: 'Debe', class: "align-middle text-right"},
            {key: 'credit', label: 'Haber', class: "align-middle text-right"},         
            {key: 'balance', label: 'Saldo', class: "align-middle text-right"},         
            {key: 'accumulated', label: 'Acumulado', class: "align-middle text-right"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          isBusy: false,
        },                 
        tableDetailsPurchase: {
          iva : {
            items: [],
            fields: [
              {key: 'iva_condition', label: 'IVA', class:"align-middle", width:"25%"},        
              {key: 'amount_net', label: 'Neto', class:"align-middle text-right", width:"25%"},
              {key: 'amount_iva', label: 'IVA', class:"align-middle text-right", width:"25%"},
              {key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"25%"},
            ]
          },
          percepcion : {
            items: [],
            fields: [
              {key: 'percepcion', label: 'Percepción', class:"align-middle", width:"75%"},
              {key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"25%"},
            ]
          },         
          accounting : {
            items: [],
            fields: [
              {key: 'code', label: 'Código', class:"align-middle", width:"20%"},
              {key: 'name', label: 'Nombre', class:"align-middle", width:"40%"},
              {key: 'debit', label: 'Debe', class:"align-middle", width:"20%"},
              {key: 'credit', label: 'Haber', class:"align-middle", width:"20%"},
            ]
          },
          imputation : {
            items: [],
            fields: [
              {key: 'date', label: 'Fecha', class:"align-middle", width:"20%"},
              {key: 'reference', label: 'Referencia', class:"align-middle", width:"50%"},
              {key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"30%"},
            ]
          },              
        },
        tableDetailsPayments: {
          payment : {
            items: [],
            fields: [
              {key: 'medio', label: 'Medio', class:"align-middle text-left text-truncate", width:"20%"},
              {key: 'description', label: 'Detalle', class:"align-middle text-left text-truncate", width:"60%"},
              {key: 'amount', label: 'Importe', class:"align-middle text-right", width:"20%"},
            ]
          },       
          accounting : {
            items: [],
            fields: [
              {key: 'code', label: 'Código', class:"align-middle", width:"150px"},
              {key: 'name', label: 'Nombre', class:"align-middle", width:"250px"},
              {key: 'debit', label: 'Debe', class:"align-middle", width:"150px"},               
              {key: 'credit', label: 'Haber', class:"align-middle", width:"150px"},
            ]
          },          
          imputation : {
            items: [],
            fields: [
              {key: 'date', label: 'Fecha', class:"align-middle text-center", width:"15%"},
              {key: 'voucher', label: 'Comprobante', class:"align-middle text-left text-truncate", width:"40%"},
              {key: 'amount_total', label: 'Importe Total', class:"align-middle text-right", width:"15%"},
              {key: 'amount_pending', label: 'Importe Pendiente', class:"align-middle text-right", width:"15%"},
              {key: 'amount_impute', label: 'Importe Imputado', class:"align-middle text-right", width:"15%"},
            ]
          },    
        },
        crud: {
          print: {
            reference: '',
            id: 0,
            key: 0,
          }     
        },
        modal: {
          detailPurchase: {
            active: false, 
            title: '',
          },  
          detailPayments: {
            active: false, 
            title: '',
          },                      
        },  
        filters: {
          date_start: '',
          date_end: '',
          suppliers: null,
          suppliers_id: 0,          
          points_sales: null,
          points_sales_id: 0,
        }, 
        tabSelect: 'invoice', 
        itemSelectedPurchase: null,        
        itemSelectedPayments: null,        
        itemSelectedReceipts: null,    
        arr: {
          invoice: [],
        }        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()

      //this.filters.suppliers_id = 2
      //this.filterReport()      
    },
    computed: {
      // MODULES
      showContabilidad() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {                             
            status = true                       
          }
        })        
        return status
      },

      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      }, 

      formartedItemsHistory () {
        if (!this.tableHistory.items) return []
        return this.tableHistory.items.map(item => {
          if(!item.print){
            item._rowVariant  = 'secondary'
          }
          
          return item
        })
      },
      formartedItemsPending () {
        if (!this.tablePending.items) return []
        return this.tablePending.items.map(item => {
          if(!item.print){
            item._rowVariant  = 'secondary'
          }
          
          return item
        })
      },
      formartedItemsInvoice () {
        if (!this.tableInvoice.items) return []
        return this.tableInvoice.items.map(item => {
          if(!item.print){
            item._rowVariant  = 'secondary'
          }
          
          return item
        })
      }                    
    },
    methods: {
      onRowSelected(item) {
        this.arr.invoice.forEach((element, index) => {  
          if(item.length) {  
            if(item[0].id>0 && item[0].typeOperation=='purchase') {
              if(element.id == item[0].id) {
                if(this.tableInvoice.items[index]._showDetails) {
                  this.tableInvoice.items[index]._showDetails = false
                } else {                
                  this.tableInvoice.rowSelected = index
                  this.filterReport()                
                }                       
              }
            }                        
          } else {
            this.tableInvoice.items[index]._showDetails = false
          }         
        })  
      },      
      filterLoad() { 
        this.loadSuppliers()
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      loadSuppliers (object) {        
        if(object){
          this.filters.suppliers = object
          this.filters.suppliers_id = object.id      
          
          this.filterReport()                
        } else {
          this.filters.suppliers = null
          this.filters.suppliers_id = 0

          this.tableHistory.items = []
          this.tablePending.items = []             
        }
      }, 
      loadPointSales (object) {
        if(object){
          this.filters.points_sales = object
          this.filters.points_sales_id = object.id      

          this.filterReport()                
        } else {
          this.filters.points_sales = null
          this.filters.points_sales_id = 0   
          
          this.filterReport()                          
        }
      },
      filterReport(){
        if(!this.filters.suppliers_id) {
          this.$awn.alert("Debe seleccionar un proveedor");
          return false;
        }
        
        this.tablePending.isBusy = true
        this.tableHistory.isBusy = true
        this.tableInvoice.isBusy = true
        
        if(this.tabSelect=='history') {
          var resultHistory = serviceAPI.filtrarCurrentsAccounts(this.filters)        
          resultHistory.then((response) => {
            this.tablePending.items = []
            this.tableInvoice.items = []
            this.tableHistory.items = response.data  

            this.tablePending.isBusy = false
            this.tableHistory.isBusy = false     
            this.tableInvoice.isBusy = false     
          })
        }
        if(this.tabSelect=='pending') {          
          var resultPending = serviceAPI.filtrarCurrentsAccountsPending(this.filters)        
          resultPending.then((response) => {                                
            this.tableHistory.items = []
            this.tableInvoice.items = []
            this.tablePending.items = response.data                        

            this.tablePending.isBusy = false
            this.tableHistory.isBusy = false
            this.tableInvoice.isBusy = false
          })                    
        }   
        if(this.tabSelect=='invoice') {          
          var resultInvoice = serviceAPI.filtrarCurrentsAccountsInvoice(this.filters)        
          resultInvoice.then((response) => {                                
            this.tableHistory.items = []
            this.tablePending.items = []
            this.tableInvoice.items = response.data
            
            this.arr.invoice  = response.data

            this.tablePending.isBusy = false
            this.tableHistory.isBusy = false
            this.tableInvoice.isBusy = false

            if(this.tableInvoice.rowSelected!=null) {              
              this.tableInvoice.items[this.tableInvoice.rowSelected]._showDetails = true      
              
              var result = serviceAPI.filtrarComprobanteById({
                id: this.tableInvoice.items[this.tableInvoice.rowSelected].regID,
              }) 
              result.then((response) => {    
                var data = response.data
                this.itemSelectedPurchase = data

                // IMPUTACIONES
                var arrImputations = []        
                if(data.payments) {
                  data.payments.forEach(element => {            
                    arrImputations.push({              
                      date: element.payment.date,
                      reference: element.payment.type_voucher.name + ' ' + element.payment.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.payment.number.toString().padStart(8,'0'),
                      amount_total: element.amount_total
                    })
                  });
                }

                if(data.purchase_related) {          
                  data.purchase_related.forEach(element => {              
                    arrImputations.push({
                      date: element.purchase_related.date,
                      reference: element.purchase_related.type_voucher.name + ' ' + element.purchase_related.point_sale.toString().padStart(4,'0') + '-' + element.purchase_related.number.toString().padStart(8,'0'),
                      amount_total: element.amount_total
                    })
                  });
                }   
                
                if(data.purchase_related_n_c) {          
                  data.purchase_related_n_c.forEach(element => {              
                    arrImputations.push({
                      date: element.purchase.date,
                      reference: element.purchase.type_voucher.name + ' ' + element.purchase.point_sale.toString().padStart(4,'0') + '-' + element.purchase.number.toString().padStart(8,'0'),
                      amount_total: element.amount_total
                    })
                  });
                }    
                   
                this.tableDetailsPurchase.imputation.items = arrImputations              
              })
            }            
          })                    
        }                     
      },
      sendPrinter(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.regID
        this.crud.print.reference=item.reportDefault        
      },
      setTabActive(type) {        
        this.tabSelect = type
        if(this.filters.suppliers_id) {
          this.filterReport()                
        }
      },  
      
      // DETALLE
      openDetail(item) {                
        if(item.typeOperation == 'purchase') {
          this.openDetailPuerchase(item)
        } else {
          this.openDetailPayments(item)
        }
      }, 
      openDetailPuerchase(item) {                       
        var result = serviceAPI.filtrarComprobanteById({
          id: item.regID,
        })        
        result.then((response) => {               
          var data = response.data
          this.itemSelectedPurchase = data
        
        // IVA / PERCEPCIONES
        this.tableDetailsPurchase.iva.items = this.itemSelectedPurchase.net      
        this.tableDetailsPurchase.percepcion.items = this.itemSelectedPurchase.perceptions        

        // IMPUTACIONES
        var arrImputations = []        
        if(item.payments) {
          item.payments.forEach(element => {            
            arrImputations.push({              
              date: element.payment.date,
              reference: element.payment.type_voucher.name + ' ' + element.payment.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.payment.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }

        if(item.purchase_related) {          
          item.purchase_related.forEach(element => {              
            arrImputations.push({
              date: element.purchase_related.date,
              reference: element.purchase_related.type_voucher.name + ' ' + element.purchase_related.point_sale.toString().padStart(4,'0') + '-' + element.purchase_related.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }   
        
        if(item.purchase_related_n_c) {          
          item.purchase_related_n_c.forEach(element => {              
            arrImputations.push({
              date: element.purchase.date,
              reference: element.purchase.type_voucher.name + ' ' + element.purchase.point_sale.toString().padStart(4,'0') + '-' + element.purchase.number.toString().padStart(8,'0'),
              amount_total: element.amount_total
            })
          });
        }   
  
        this.tableDetailsPurchase.imputation.items = arrImputations

        // CONTABILIDAD
        if(this.itemSelectedPurchase.accounting_entries){
          if(this.itemSelectedPurchase.accounting_entries.details){
            this.tableDetailsPurchase.accounting.items = this.itemSelectedPurchase.accounting_entries.details
          } else {
            this.tableDetailsPurchase.accounting.items = []
          }
        } else {
          this.tableDetailsPurchase.accounting.items = []
        }          

          this.modal.detailPurchase.title = "Detalle del Comprobante"
          this.modal.detailPurchase.active = true
        })                         
      },    
      openDetailPayments(item) {
        var result = serviceAPI.filtrarPagosById({
          id: item.regID,
        })        
        result.then((response) => {               
          var data = response.data
          this.itemSelectedPayments = data

          this.tableDetailsPayments.payment.items = this.itemSelectedPayments.details_payments
          this.tableDetailsPayments.imputation.items = this.itemSelectedPayments.details_imputations

          if(this.itemSelectedPayments.accounting_entries){
            if(this.itemSelectedPayments.accounting_entries.details){
              this.tableDetailsPayments.accounting.items = this.itemSelectedPayments.accounting_entries.details
            } else {
              this.tableDetailsPayments.accounting.items = []
            }
          } else {
            this.tableDetailsPayments.accounting.items = []
          }

          this.modal.detailPayments.title = "Detalle del Comprobante"
          this.modal.detailPayments.active = true          
        })
      },
      getPendingVoucherPurchase(item) {
        return parseFloat(item.amount_total) - parseFloat(item.amount_impute)
      },              
      calcPendingVoucherPayments(item) {
        return parseFloat(item.purchase.amount_total) - parseFloat(item.purchase.amount_impute)
      },              
    }    
  }
</script>
<style scoped>
  .crud-purchase-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }  
  .crud-purchase-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }    
  .table-full-detail-purchase {
    overflow: auto;
    max-height: 350px;    
  }  
  .crud-payments-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-payments-value-table-custom {
    padding-left: 5px;
    white-space: pre;
  }   
  .current-accounts-accumulate-negative-total {  
    font-size: 16px;    
    color: white;
    background: red;
    padding: 3px;    
  }
  .current-accounts-accumulate-positive-total {
    font-size: 16px;
    color: white;
    background: green;
    padding: 3px;           
  }  
  .current-accounts-accumulate-negative {      
    color: red;
  }
  .current-accounts-accumulate-positive {    
    color: green;
  }   
</style>